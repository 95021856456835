<template>
  <div>
    <b-card
      class="bg-transparent shadow-lg"
      header-bg-variant="info"
      header-class="py-25"
      body-class="p-50"
    >
      <template #header>
        <span
          class="text-white font-weight-bolder m-0"
          :class="isMobileView ? 'font-medium-2' : 'font-medium-4'"
        >
          Thêm chuyến bay
        </span>
      </template>

      <BOverlay
        :show="loading"
        rounded="sm"
        variant="transparent"
        spinner-variant="info"
        opacity="0.95"
        no-fade
      >
        <!-- ANCHOR - HÀNH TRÌNH -->
        <AppCollapse
          type="card"
          class="mb-75"
        >
          <AppCollapseItem
            title
            isVisible
            classHeader="border-bottom mb-50"
          >
            <template #header>
              <div :class="`text-airline fw-700 ${isMobileView ? 'font-medium-2' : 'font-medium-3'}`">
                Hành trình
              </div>
            </template>

            <div v-if="!isEmpty(searchFlightArray)">
              <div
                v-for="(flight, indexFlight) of searchFlightArray"
                :key="indexFlight"
              >
                <template v-if="selectedTrips[indexFlight]">
                  <BRow
                    v-for="(segment, indexSegment) in selectedTrips[indexFlight]"
                    :key="indexSegment"
                    class="d-flex"
                    no-gutters
                  >
                    <BCol
                      cols="12"
                      class="d-flex align-items-center"
                    >
                      <span
                        :class="`mr-25 mr-md-50 font-weight-bolder
                    ${ ['xs', 'sm', 'md'].includes(appBreakPoint) ? 'font-small-4' : 'font-medium-1' }`"
                      >
                        <!-- {{ `${indexFlight + 1}*${indexSegment + 1}` }} -->
                        {{ !indexSegment ? `${indexFlight + 1}.` : '' }}
                      </span>

                      <b-form-input
                        v-if="segment"
                        :value="`${getSortTripBySegment(segment, true)}`"
                        :class="`text-body fw-700
                        ${ ['xs', 'sm', 'md'].includes(appBreakPoint) ? 'font-medium-1': 'font-medium-2' }
                      `"
                        style="min-width: 300px"
                        disabled
                      />
                      <b-form-input
                        v-else
                        :value="`Chưa chọn chặng ${indexSegment + 1}`"
                        class="text-warning font-italic"
                        disabled
                      />
                    </BCol>

                    <BCol
                      cols="12"
                      :class="`fw-700 mt-75 ml-50
                        ${['LL','DS'].includes(segment.bookingClass.status) ? 'text-danger' : 'text-info'}
                      `"
                    >
                      Trạng thái: {{ `${['LL','DS'].includes(segment.bookingClass.status) ? 'Đặt sổ chờ' : '' } ${segment.bookingClass.status}` }}
                    </BCol>
                  </BRow>
                </template>

                <b-form-input
                  v-else
                  value="Chưa chọn chuyến bay"
                  class="text-danger font-italic"
                  disabled
                />
              </div>
            </div>
          </AppCollapseItem>
        </AppCollapse>

        <!-- ANCHOR - TÍNH GIÁ -->
        <AppCollapse
          type="card"
          class="mb-75"
        >
          <AppCollapseItem
            title
            isVisible
            classHeader="border-bottom mb-50"
          >
            <template #header>
              <div :class="`text-airline fw-700 ${isMobileView ? 'font-medium-2' : 'font-medium-3'}`">
                Tính giá
              </div>
            </template>

            <b-row class="calc_price_class_booking_row">
              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group
                  :label="$t('flight.fareType')"
                  label-for="fareType"
                >
                  <b-form-select
                    id="fareType"
                    v-model="fareType"
                    class="fw-700 text-body"
                  >
                    <b-form-select-option :value="null">
                      {{ $t('flight.default') }}
                    </b-form-select-option>

                    <b-form-select-option
                      v-for="item of fareTypeOptions"
                      :key="item"
                      :value="item"
                    >
                      {{ $t(`flight.searchClass.${item}`) }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group
                  :label="$t('flight.currency')"
                  label-for="currency"
                >
                  <b-form-select
                    id="currency"
                    v-model="currency"
                    class="fw-700 text-body"
                  >
                    <!-- @change="val => this.$store.dispatch('app-flight-v2/setClassBookingCurrency', val)" -->
                    <b-form-select-option value="VND">
                      VND
                    </b-form-select-option>
                    <b-form-select-option value="USD">
                      USD
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                sm="6"
              >
                <b-form-checkbox
                  v-model="isBargainFinder"
                  class="custom-control-warning"
                  switch
                >
                  <span class="text-nowrap font-medium-1 font-weight-bolder">
                    <!-- {{ $t('reservation.bargainFinder') }} -->
                    Tính giá rẻ nhất
                  </span>
                </b-form-checkbox>
              </b-col>

              <b-col
                cols="12"
                sm="6"
              >
                <b-form-checkbox
                  v-model="keepGroup"
                  class="custom-control-warning"
                  switch
                >
                  <span class="text-nowrap font-medium-1 font-weight-bolder">
                    <!-- {{ $t('reservation.bargainFinder') }} -->
                    Giữ hạng dịch vụ
                  </span>
                </b-form-checkbox>
              </b-col>

              <b-col
                cols="12"
                sm="6"
              >
                <b-form-checkbox
                  v-model="isCalcOnlySegment"
                  class="custom-control-warning"
                  switch
                >
                  <span class="text-nowrap font-medium-1 font-weight-bolder">
                    Chỉ tính giá chặng này
                  </span>
                </b-form-checkbox>
              </b-col>

              <b-col
                cols="12"
                sm="6"
              >
                <b-form-checkbox
                  v-model="isSavePriceToBooking"
                  class="custom-control-warning"
                  switch
                >
                  <span class="font-medium-1 font-weight-bolder">
                    Lưu giá vé
                  </span>
                </b-form-checkbox>
              </b-col>

              <b-col
                cols="12"
                class="text-danger font-small-4 fw-700"
              >
                Lưu ý: Trong trường hợp đặt sổ chờ (HL), đặt DS hoặc đặt nhiều hạng trên 1 chuyến bay bạn nên tắt chức năng lưu giá vé tránh bị lỗi !
              </b-col>
            </b-row>
          </AppCollapseItem>
        </AppCollapse>

        <!-- ANCHOR - BUTTONs -->
        <div class="d-flex justify-content-around mb-75">
          <b-button
            variant="outline-secondary"
            pill
            @click="() => this.$router.go(-1)"
          >
            {{ $t('flight.Back') }}
          </b-button>

          <b-button
            variant="warning"
            pill
            @click="calculatePriceHandle"
          >
            <!-- {{ $t('flight.submit') }} -->
            Tính giá vé
          </b-button>

          <b-button
            variant="gradient"
            pill
            @click="handleOpenModalConfirmAddFlight"
          >
            <!-- {{ $t('flight.submit') }} -->
            Thêm chuyến bay
          </b-button>
        </div>

        <!-- ANCHOR - GIÁ VÉ -->
        <AppCollapse
          v-if="resultCalculatePrice"
          type="card"
          class="mb-75"
        >
          <AppCollapseItem
            title
            isVisible
            classHeader="border-bottom mb-50"
          >
            <template #header>
              <div :class="`text-airline fw-700 ${isMobileView ? 'font-medium-2' : 'font-medium-3'}`">
                Giá vé ( <span class="text-airline">{{ resultCalculatePrice[0].currency }}</span> )
              </div>
            </template>

            <b-tabs
              nav-class="bg-gray"
              pills
            >
              <b-tab
                :title="$t(`flight['Pricing information']`)"
                title-item-class="fw-700"
                active
              >
                <BTable
                  small
                  responsive
                  hovered
                  outlined
                  :items="resultCalculatePrice"
                  class="mb-0 mt-25"
                  thead-tr-class="text-nowrap"
                  tbody-tr-class="text-nowrap text-right"
                  :fields="['passenger', 'quantity', 'net', 'tax', 'total']"
                >
                  <template
                    v-for="(column, indexColumn) in [
                      'passenger',
                      'quantity',
                      'net',
                      'tax',
                      'total',
                    ]"
                    #[`head(${column})`]="data"
                  >
                    <div
                      :key="indexColumn"
                      :class="`${
                        indexColumn === 0 ? 'text-center' : 'text-right'
                      }`"
                    >
                      {{ $t(`reservation.${data.column}`) }}
                    </div>
                  </template>

                  <template #cell(passenger)="{ item }">
                    {{ $t(`flight.${capitalize(item.passengerRequested.paxType)}`) }}
                  </template>
                  <template #cell(quantity)="{ item }">
                    {{ item.passengerRequested.quantity }}
                  </template>
                  <template #cell(net)="{ item }">
                    <span class="fw-600">
                      {{ formatCurrency(item.basePrice, item.currency) }}
                    </span>
                  </template>
                  <template #cell(tax)="{ item }">
                    <span class="fw-600">
                      {{ formatCurrency(item.totalTaxes, item.currency) }}
                    </span>
                  </template>
                  <template #cell(total)="{ item }">
                    <span class="fw-600 text-airline">
                      {{ formatCurrency(item.totalPrice, item.currency) }}
                    </span>
                  </template>
                </BTable>

                <div class="text-right fw-700 mr-50">
                  <span class="mr-2">
                    Tổng tiền vé
                  </span>
                  <span class="text-danger">
                    {{ formatCurrency(resultCalculatePrice.reduce((acc, item) => acc + item.totalPrice, 0), resultCalculatePrice[0].currency) }}
                  </span>
                </div>
              </b-tab>

              <b-tab
                title="Thông tin giá"
                title-item-class="fw-700 border-left-secondary"
              >
                <ShortenBooking :shorten-text="resultCalculatePrice?.[0]?.fareDetails || undefined" />
              </b-tab>

              <b-tab
                title="Điều kiện"
                title-item-class="fw-700 border-left-secondary"
              >
                <ShortenBooking :shorten-text="resultCalculatePrice?.[0]?.fareRules || undefined" />
              </b-tab>

              <b-tab
                title="Hành lý"
                title-item-class="fw-700 border-left-secondary border-right-secondary"
              >
                <ShortenBooking :shorten-text="resultCalculatePrice?.[0]?.bagInfo || undefined" />
              </b-tab>
            </b-tabs>
          </AppCollapseItem>
        </AppCollapse>
      </BOverlay>
    </b-card>

    <b-card
      class="bg-transparent shadow-lg"
      header-bg-variant="warning"
      header-class="py-50 fw-700 font-medium-2 text-white"
      body-class="p-75"
    >
      <template #header>
        Booking
      </template>

      <b-form-textarea
        v-model="shortenText"
        class="font-weight-bolder text-uppercase overflow-auto"
        rows="10"
        disabled
        max-rows="30"
        style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif;"
      />
    </b-card>

    <ModalModifyConfirmAddFlightClassBooking
      v-if="tripSelectToAdd && calculatePriceOptions"
      :tripSelectToAdd.sync="tripSelectToAdd"
      :calculatePriceOptions.sync="calculatePriceOptions"
      :booking-data="bookingData"
      @updateBookingText="(bookingTextValue) => shortenText = bookingTextValue"
    />
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormInput, BOverlay,
  BFormGroup, BFormSelectOption, BFormSelect,
  BFormCheckbox, BFormTextarea, BTable, BTabs, BTab,
} from 'bootstrap-vue'
import { toRefs, ref } from '@vue/composition-api'
import { groupBy, capitalize, isEmpty } from 'lodash-es'

// import { apiBooking } from '@/api/'
import { getUserData } from '@/api/auth/utils'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'

import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BOverlay,
    BFormTextarea,
    BFormCheckbox,
    BFormGroup,
    BFormSelectOption,
    BFormSelect,
    BTable,
    BTabs,
    BTab,

    AppCollapse: () => import('@/@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@/@core/components/app-collapse/AppCollapseItem.vue'),
    ShortenBooking: () => import('./components/ShortenBooking.vue'),
    ModalModifyConfirmAddFlightClassBooking: () => import('./components/ModalModifyConfirmAddFlightClassBooking.vue'),
  },
  props: {
    selectedTrips: {
      type: Array,
      default: () => [],
    },
    bookingData: {
      type: Object,
      default: () => {},
    },
    searchFlightArray: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { selectedTrips, bookingData } = toRefs(props)
    const { toastError } = useToast()
    const { getSortTripBySegment } = useClassBookingHandle()

    const { delay, addFlightsPrice } = useReservationHandle()

    const loading = ref(false)
    const shortenText = ref(null)
    const resultCalculatePrice = ref(null)

    const fareTypeOptions = ['M1B', 'CRF', 'NRF']
    const currencyOptions = ['VND', 'USD']

    const currency = ref('VND')
    const fareType = ref(null)

    const isBargainFinder = ref(false)
    const keepGroup = ref(false)
    const isCalcOnlySegment = ref(false)
    const isSavePriceToBooking = ref(false)

    function getSegmentUniqueId(newSegment) {
      let result = null

      const { flightNumber, departure, arrival } = newSegment

      selectedTrips.value.forEach(trip => {
        trip.forEach(segment => {
          if (segment.flightNumber === flightNumber && segment.departure === departure && segment.arrival === arrival) {
            result = segment.uniqueSegmentId
          }
        })
      })

      console.log({ getSegmentUniqueId: result })
      return result
    }

    function isValidSavePrice() {
      if (!isSavePriceToBooking.value) {
        return true
      }

      const isInvalid = selectedTrips.value.some(trip => trip.some(segment => segment.bookingClass.status === 'LL' || segment.bookingClass.status === 'DS'))
      if (isInvalid) {
        toastError({ title: 'Chuyến bay đã chọn có hành trình LL/DS, vui lòng tắt chức năng LƯU GIÁ VÉ .' })
        return false
      }

      return true
    }

    async function calculatePriceHandle() {
      if (isEmpty(selectedTrips.value)) {
        toastError({ title: 'Vui lòng chọn hành trình!' })
      }

      if (selectedTrips.value.some(item => !item)) {
        toastError({ title: 'Vui lòng chọn các chặng bay!' })
      }

      const checkValidSavePrice = isValidSavePrice()
      if (!checkValidSavePrice) {
        return
      }

      try {
        resultCalculatePrice.value = null
        loading.value = true

        const paxLists = bookingData.value.paxLists
        const groupedPaxLists = Object.entries(groupBy(paxLists, 'paxType'))

        const passengerTypeRequests = groupedPaxLists.map(([key, value]) => ({
          code: key,
          quantity: value.length,
        }))

        const fareBreakdowns = groupedPaxLists.map(([key, value]) => ({
          paxType: key,
          netFare: value[0]?.pricingInfo?.netFare || 0,
          tax: value[0]?.pricingInfo?.tax || 0,
          total: value[0]?.pricingInfo?.total || 0,
          currency: value[0]?.pricingInfo?.currency || 'VND',
          fareValue: value?.[0]?.fareValue || '',
        }))

        const payload = {
          contact: getUserData().employeeData.id,
          source: 'VN1A',
          airline: 'VN',
          agencyCode: getUserData().employeeData?.agency?.agencyCode,
          paxCode: bookingData.value?.paxContact?.code ?? '',
          pnrNumber: bookingData.value?.bookingCode,
          passengerTypeRequests,
          paxList: paxLists.map(pax => ({
            firstName: pax.firstName,
            lastName: pax.lastName,
            title: pax.title,
            paxType: pax.paxType,
            birthday: pax.birthday || null,
            paxId: pax.paxId,
            parentPaxId: pax.parentPaxId || '-1',
            PaxDocuments: [],
            eTicketNumber: '',
            pricingInfo: {
              tax: 0,
              total: 0,
              netFare: 0,
              currency: 'VND',
            },
          })),
          itineraries: selectedTrips.value.map(trip => ({
            source: 'VN1A',
            airline: 'VN',
            clientId: '',
            bookingKey: '',
            fareBreakdowns,
            segments: trip.map(seg => ({
              leg: seg.leg,
              segmentId: '',
              airline: seg.airline,
              departure: seg.departure,
              arrival: seg.arrival,
              departureTime: convertISODateTime(seg.departureDate, seg.departureTimezone).ISOdatetime,
              arrivalTime: convertISODateTime(seg.arrivalDate, seg.arrivalTimezone).ISOdatetime,
              flightNumber: seg.flightNumber ? String(seg.flightNumber) : '',
              fareType: '',
              fareBasisCode: '',
              bookingClass: seg?.bookingClass?.code || '',
              groupClass: '',
              marriageGrp: '',
              segmentValue: '',
              status: seg?.bookingClass?.status || '',
            })),
          })),
          fareType: fareType.value,
          currency: currency.value,
          bargainFinder: isBargainFinder.value,
          keepGroup: keepGroup.value,
          checkAllSegment: !isCalcOnlySegment.value,
          retain: isSavePriceToBooking.value,
        }

        // const resCalculatePrice = await apiBooking.calculatePrice(payload)
        const resCalculatePrice = await addFlightsPrice(payload)

        console.log({
          payload,
          selectedTrips: selectedTrips.value,
          bookingData: bookingData.value,
          resCalculatePrice,
        })

        if (!isEmpty(resCalculatePrice?.priceQuote)) {
          resultCalculatePrice.value = resCalculatePrice.priceQuote.map(priceItem => ({
            ...priceItem,
            currency: payload.currency,
          }))

          const bookingInfos = resultCalculatePrice.value[0].bookingInfos.map(newSeg => ({
            ...newSeg,
            uniqueSegmentId: getSegmentUniqueId(newSeg),
          }))

          emit('updateTripInfo', bookingInfos)
        }

        console.log({
          payload,
          selectedTrips: selectedTrips.value,
          bookingData: bookingData.value,
          resultCalculatePrice: resultCalculatePrice.value,
        })
      } catch (error) {
        console.error('Error submitRecalculatePrice:', error)
      } finally {
        loading.value = false
      }
    }

    const tripSelectToAdd = ref(null)
    const calculatePriceOptions = ref(null)

    async function handleOpenModalConfirmAddFlight() {
      if (selectedTrips.value.some(item => item === null)) {
        toastError({
          title: 'Vui lòng chọn các hành trình!',
        })
        return
      }

      const checkValidSavePrice = isValidSavePrice()
      if (!checkValidSavePrice) {
        return
      }

      tripSelectToAdd.value = selectedTrips.value
      calculatePriceOptions.value = {
        fareType: fareType.value || undefined,
        currency: currency.value || undefined,
        bargainFinder: isBargainFinder.value || undefined,
        keepGroup: keepGroup.value || undefined,
        checkAllSegment: !isCalcOnlySegment.value || undefined,
        retain: isSavePriceToBooking.value || undefined,
      }

      await delay(200)
      this.$bvModal.show('modal-modify-class-booking-add-flight-confirm')
    }

    return {
      loading,
      shortenText,
      isBargainFinder,
      keepGroup,
      currency,
      currencyOptions,
      fareTypeOptions,
      fareType,
      isCalcOnlySegment,
      isSavePriceToBooking,
      resultCalculatePrice,
      tripSelectToAdd,
      handleOpenModalConfirmAddFlight,

      isEmpty,
      capitalize,
      convertISODateTime,
      formatCurrency,
      calculatePriceHandle,
      getSortTripBySegment,
      calculatePriceOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.calc_price_class_booking_row {
  * {
    margin-bottom: .25rem;
  }
}
</style>

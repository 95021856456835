var render = function () {
  var _this = this,
    _vm$resultCalculatePr,
    _vm$resultCalculatePr2,
    _vm$resultCalculatePr3,
    _vm$resultCalculatePr4,
    _vm$resultCalculatePr5,
    _vm$resultCalculatePr6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "bg-transparent shadow-lg",
    attrs: {
      "header-bg-variant": "info",
      "header-class": "py-25",
      "body-class": "p-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-white font-weight-bolder m-0",
          class: _vm.isMobileView ? 'font-medium-2' : 'font-medium-4'
        }, [_vm._v(" Thêm chuyến bay ")])];
      },
      proxy: true
    }])
  }, [_c('BOverlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "variant": "transparent",
      "spinner-variant": "info",
      "opacity": "0.95",
      "no-fade": ""
    }
  }, [_c('AppCollapse', {
    staticClass: "mb-75",
    attrs: {
      "type": "card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "isVisible": "",
      "classHeader": "border-bottom mb-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          class: "text-airline fw-700 ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-3')
        }, [_vm._v(" Hành trình ")])];
      },
      proxy: true
    }])
  }, [!_vm.isEmpty(_vm.searchFlightArray) ? _c('div', _vm._l(_vm.searchFlightArray, function (flight, indexFlight) {
    return _c('div', {
      key: indexFlight
    }, [_vm.selectedTrips[indexFlight] ? _vm._l(_vm.selectedTrips[indexFlight], function (segment, indexSegment) {
      return _c('BRow', {
        key: indexSegment,
        staticClass: "d-flex",
        attrs: {
          "no-gutters": ""
        }
      }, [_c('BCol', {
        staticClass: "d-flex align-items-center",
        attrs: {
          "cols": "12"
        }
      }, [_c('span', {
        class: "mr-25 mr-md-50 font-weight-bolder\n                  ".concat(['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? 'font-small-4' : 'font-medium-1')
      }, [_vm._v(" " + _vm._s(!indexSegment ? "".concat(indexFlight + 1, ".") : '') + " ")]), segment ? _c('b-form-input', {
        class: "text-body fw-700\n                      ".concat(['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? 'font-medium-1' : 'font-medium-2', "\n                    "),
        staticStyle: {
          "min-width": "300px"
        },
        attrs: {
          "value": "".concat(_vm.getSortTripBySegment(segment, true)),
          "disabled": ""
        }
      }) : _c('b-form-input', {
        staticClass: "text-warning font-italic",
        attrs: {
          "value": "Ch\u01B0a ch\u1ECDn ch\u1EB7ng ".concat(indexSegment + 1),
          "disabled": ""
        }
      })], 1), _c('BCol', {
        class: "fw-700 mt-75 ml-50\n                      ".concat(['LL', 'DS'].includes(segment.bookingClass.status) ? 'text-danger' : 'text-info', "\n                    "),
        attrs: {
          "cols": "12"
        }
      }, [_vm._v(" Trạng thái: " + _vm._s("".concat(['LL', 'DS'].includes(segment.bookingClass.status) ? 'Đặt sổ chờ' : '', " ").concat(segment.bookingClass.status)) + " ")])], 1);
    }) : _c('b-form-input', {
      staticClass: "text-danger font-italic",
      attrs: {
        "value": "Chưa chọn chuyến bay",
        "disabled": ""
      }
    })], 2);
  }), 0) : _vm._e()])], 1), _c('AppCollapse', {
    staticClass: "mb-75",
    attrs: {
      "type": "card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "isVisible": "",
      "classHeader": "border-bottom mb-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          class: "text-airline fw-700 ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-3')
        }, [_vm._v(" Tính giá ")])];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "calc_price_class_booking_row"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('flight.fareType'),
      "label-for": "fareType"
    }
  }, [_c('b-form-select', {
    staticClass: "fw-700 text-body",
    attrs: {
      "id": "fareType"
    },
    model: {
      value: _vm.fareType,
      callback: function callback($$v) {
        _vm.fareType = $$v;
      },
      expression: "fareType"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.default')) + " ")]), _vm._l(_vm.fareTypeOptions, function (item) {
    return _c('b-form-select-option', {
      key: item,
      attrs: {
        "value": item
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("flight.searchClass.".concat(item))) + " ")]);
  })], 2)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('flight.currency'),
      "label-for": "currency"
    }
  }, [_c('b-form-select', {
    staticClass: "fw-700 text-body",
    attrs: {
      "id": "currency"
    },
    model: {
      value: _vm.currency,
      callback: function callback($$v) {
        _vm.currency = $$v;
      },
      expression: "currency"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": "VND"
    }
  }, [_vm._v(" VND ")]), _c('b-form-select-option', {
    attrs: {
      "value": "USD"
    }
  }, [_vm._v(" USD ")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.isBargainFinder,
      callback: function callback($$v) {
        _vm.isBargainFinder = $$v;
      },
      expression: "isBargainFinder"
    }
  }, [_c('span', {
    staticClass: "text-nowrap font-medium-1 font-weight-bolder"
  }, [_vm._v(" Tính giá rẻ nhất ")])])], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.keepGroup,
      callback: function callback($$v) {
        _vm.keepGroup = $$v;
      },
      expression: "keepGroup"
    }
  }, [_c('span', {
    staticClass: "text-nowrap font-medium-1 font-weight-bolder"
  }, [_vm._v(" Giữ hạng dịch vụ ")])])], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.isCalcOnlySegment,
      callback: function callback($$v) {
        _vm.isCalcOnlySegment = $$v;
      },
      expression: "isCalcOnlySegment"
    }
  }, [_c('span', {
    staticClass: "text-nowrap font-medium-1 font-weight-bolder"
  }, [_vm._v(" Chỉ tính giá chặng này ")])])], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.isSavePriceToBooking,
      callback: function callback($$v) {
        _vm.isSavePriceToBooking = $$v;
      },
      expression: "isSavePriceToBooking"
    }
  }, [_c('span', {
    staticClass: "font-medium-1 font-weight-bolder"
  }, [_vm._v(" Lưu giá vé ")])])], 1), _c('b-col', {
    staticClass: "text-danger font-small-4 fw-700",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Lưu ý: Trong trường hợp đặt sổ chờ (HL), đặt DS hoặc đặt nhiều hạng trên 1 chuyến bay bạn nên tắt chức năng lưu giá vé tránh bị lỗi ! ")])], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-around mb-75"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "pill": ""
    },
    on: {
      "click": function click() {
        return _this.$router.go(-1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Back')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "warning",
      "pill": ""
    },
    on: {
      "click": _vm.calculatePriceHandle
    }
  }, [_vm._v(" Tính giá vé ")]), _c('b-button', {
    attrs: {
      "variant": "gradient",
      "pill": ""
    },
    on: {
      "click": _vm.handleOpenModalConfirmAddFlight
    }
  }, [_vm._v(" Thêm chuyến bay ")])], 1), _vm.resultCalculatePrice ? _c('AppCollapse', {
    staticClass: "mb-75",
    attrs: {
      "type": "card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "isVisible": "",
      "classHeader": "border-bottom mb-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          class: "text-airline fw-700 ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-3')
        }, [_vm._v(" Giá vé ( "), _c('span', {
          staticClass: "text-airline"
        }, [_vm._v(_vm._s(_vm.resultCalculatePrice[0].currency))]), _vm._v(" ) ")])];
      },
      proxy: true
    }], null, false, 1380363860)
  }, [_c('b-tabs', {
    attrs: {
      "nav-class": "bg-gray",
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": _vm.$t("flight['Pricing information']"),
      "title-item-class": "fw-700",
      "active": ""
    }
  }, [_c('BTable', {
    staticClass: "mb-0 mt-25",
    attrs: {
      "small": "",
      "responsive": "",
      "hovered": "",
      "outlined": "",
      "items": _vm.resultCalculatePrice,
      "thead-tr-class": "text-nowrap",
      "tbody-tr-class": "text-nowrap text-right",
      "fields": ['passenger', 'quantity', 'net', 'tax', 'total']
    },
    scopedSlots: _vm._u([_vm._l(['passenger', 'quantity', 'net', 'tax', 'total'], function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: indexColumn,
            class: "".concat(indexColumn === 0 ? 'text-center' : 'text-right')
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(passenger)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(_vm.capitalize(item.passengerRequested.paxType)))) + " ")];
      }
    }, {
      key: "cell(quantity)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.passengerRequested.quantity) + " ")];
      }
    }, {
      key: "cell(net)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "fw-600"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.basePrice, item.currency)) + " ")])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "fw-600"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalTaxes, item.currency)) + " ")])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "fw-600 text-airline"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice, item.currency)) + " ")])];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "text-right fw-700 mr-50"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" Tổng tiền vé ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resultCalculatePrice.reduce(function (acc, item) {
    return acc + item.totalPrice;
  }, 0), _vm.resultCalculatePrice[0].currency)) + " ")])])], 1), _c('b-tab', {
    attrs: {
      "title": "Thông tin giá",
      "title-item-class": "fw-700 border-left-secondary"
    }
  }, [_c('ShortenBooking', {
    attrs: {
      "shorten-text": ((_vm$resultCalculatePr = _vm.resultCalculatePrice) === null || _vm$resultCalculatePr === void 0 ? void 0 : (_vm$resultCalculatePr2 = _vm$resultCalculatePr[0]) === null || _vm$resultCalculatePr2 === void 0 ? void 0 : _vm$resultCalculatePr2.fareDetails) || undefined
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Điều kiện",
      "title-item-class": "fw-700 border-left-secondary"
    }
  }, [_c('ShortenBooking', {
    attrs: {
      "shorten-text": ((_vm$resultCalculatePr3 = _vm.resultCalculatePrice) === null || _vm$resultCalculatePr3 === void 0 ? void 0 : (_vm$resultCalculatePr4 = _vm$resultCalculatePr3[0]) === null || _vm$resultCalculatePr4 === void 0 ? void 0 : _vm$resultCalculatePr4.fareRules) || undefined
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Hành lý",
      "title-item-class": "fw-700 border-left-secondary border-right-secondary"
    }
  }, [_c('ShortenBooking', {
    attrs: {
      "shorten-text": ((_vm$resultCalculatePr5 = _vm.resultCalculatePrice) === null || _vm$resultCalculatePr5 === void 0 ? void 0 : (_vm$resultCalculatePr6 = _vm$resultCalculatePr5[0]) === null || _vm$resultCalculatePr6 === void 0 ? void 0 : _vm$resultCalculatePr6.bagInfo) || undefined
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-card', {
    staticClass: "bg-transparent shadow-lg",
    attrs: {
      "header-bg-variant": "warning",
      "header-class": "py-50 fw-700 font-medium-2 text-white",
      "body-class": "p-75"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v(" Booking ")];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase overflow-auto",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif"
    },
    attrs: {
      "rows": "10",
      "disabled": "",
      "max-rows": "30"
    },
    model: {
      value: _vm.shortenText,
      callback: function callback($$v) {
        _vm.shortenText = $$v;
      },
      expression: "shortenText"
    }
  })], 1), _vm.tripSelectToAdd && _vm.calculatePriceOptions ? _c('ModalModifyConfirmAddFlightClassBooking', {
    attrs: {
      "tripSelectToAdd": _vm.tripSelectToAdd,
      "calculatePriceOptions": _vm.calculatePriceOptions,
      "booking-data": _vm.bookingData
    },
    on: {
      "update:tripSelectToAdd": function updateTripSelectToAdd($event) {
        _vm.tripSelectToAdd = $event;
      },
      "update:trip-select-to-add": function updateTripSelectToAdd($event) {
        _vm.tripSelectToAdd = $event;
      },
      "update:calculatePriceOptions": function updateCalculatePriceOptions($event) {
        _vm.calculatePriceOptions = $event;
      },
      "update:calculate-price-options": function updateCalculatePriceOptions($event) {
        _vm.calculatePriceOptions = $event;
      },
      "updateBookingText": function updateBookingText(bookingTextValue) {
        return _vm.shortenText = bookingTextValue;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }